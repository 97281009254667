import './NavigationBar.css';
import {
    Link,
    useMatch,
    useResolvedPath
} from "react-router-dom";

export default function NavigationBar() {
    return (
        <nav className='navigation-bar'>
            <div className='navigation-bar-surface-tint'>

                <CustomLink
                    to="/"
                    state={{ title: 'ANEP' }}
                    icon={
                        <span className='material-symbols'>
                            home
                        </span>
                    }
                    activeicon={
                        <span className='material-symbols active'>
                            home
                        </span>
                    }>
                    Inicio
                </CustomLink>

                <CustomLink
                    to="calendario"
                    state={{ title: 'Calendario' }}
                    icon={
                        <span className='material-symbols'>
                            event
                        </span>
                    }
                    activeicon={
                        <span className='material-symbols active'>
                            event
                        </span>
                    }>
                    Calendario
                </CustomLink>


                <CustomLink
                    to="noticias"
                    state={{ title: 'Noticias' }}
                    icon={
                        <span className='material-symbols'>
                            newsmode
                        </span>
                    }
                    activeicon={
                        <span className='material-symbols active'>
                            newsmode
                        </span>
                    }>
                    Noticias
                </CustomLink>

                <CustomLink
                    to="convenios"
                    state={{ title: 'Convenios' }}
                    icon={
                        <span className='material-symbols'>
                            loyalty
                        </span>
                    }
                    activeicon={
                        <span className='material-symbols active'>
                            loyalty
                        </span>
                    }>
                    Convenios
                </CustomLink>

                {/* <CustomLink
                    to="legal"
                    state={{ title: 'Legal' }}
                    icon={<BriefcaseRegular fontSize={24} />}
                    activeicon={<BriefcaseFilled fontSize={24} />}>
                    Legal
                </CustomLink> */}

                {/* <CustomLink
                    to="carne"
                    state={{ title: 'Carné' }}
                    icon={<PersonRegular fontSize={24} />}
                    activeicon={<PersonFilled fontSize={24} />}>
                    Carné
                </CustomLink> */}

            </div>
        </nav>
    )
};

function CustomLink({ children, to, ...props }) {
    let resolved = useResolvedPath(to);
    let match = useMatch({ path: resolved.pathname, end: true });

    return (
        <Link to={to} {...props} className="navigation-bar-item label-medium" style={match && {fontWeight: '700'}}>
            <div className={match ? 'navigation-bar-active-indicator navigation-bar-active-indicator-match' : 'navigation-bar-active-indicator'}>
                {match ? props.activeicon : props.icon}
            </div>
            {children}
        </Link>
    );
}