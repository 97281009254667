import { Navigate, useSearchParams } from "react-router-dom";

export default function EmailSent({ authState }) {
    let [searchParams, setSearchParams] = useSearchParams();


    console.log(authState)

    if (authState) {
        return <Navigate to="/" replace={true} />
    }

    return (
        <div className='page'>
            <div className='email-sent-card'>
                <span className="material-symbols" style={{ fontSize: '36px' }}>mail</span>
                <h2>Email enviado exitosamente</h2>
                <p>Se ha enviado un correo a <b>{searchParams.get('email')}</b>, revisa tu bandeja de entrada y da click en el link de confirmación, serás redirigido/a a esta misma app.</p>
            </div>
        </div>
    );
}