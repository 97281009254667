import { useEffect } from "react";
import './Directorio.css';
import {
    BookCoinsRegular,
    CallRegular
} from "@fluentui/react-icons";
// ASSETS
import supportIcon from '../assets/support-icon.png';
import profileIcon from '../assets/profile-icon.png';
import { Navigate } from "react-router-dom";

export default function Directorio ({ authState, userVerified }) {

    // useEffect(() => {
    //     var acc = document.getElementsByClassName("accordion");
    //     var i;

    //     for (i = 0; i < acc.length; i++) {
    //         acc[i].addEventListener("click", function() {
    //             // this.classList.toggle("active");
    //             var panel = this.nextElementSibling;
    //             if (panel.style.display === "block") {
    //             panel.style.display = "none";
    //             } else {
    //             panel.style.display = "block";
    //             }
    //         });
    //     }
    // }, []);

    return(
        <div className='page'>
            {/* <h1>Directorio</h1> */}
            {(authState !== true || userVerified !== true) && <Navigate to="/restringido" replace={true} /> }
            <button className="accordion">
                <BookCoinsRegular fontSize={24} className="accordion-icon" />
                Central telefónica
                {/* <ChevronDownRegular fontSize={24} className="accordion-expand" /> */}
            </button>
            <div className="panel" style={{display: 'block'}}>
                <a className="phonebook-item" href="tel:22578233">
                    <img className="phonebook-photo" src={supportIcon}/>
                    <span>
                        <p>Central telefónica 1</p>
                        <p><small>2257 8233</small></p>
                    </span>
                    <CallRegular fontSize={24} className="phonebook-action"/>
                </a>
                <a className="phonebook-item" href="tel:22579924">
                    <img className="phonebook-photo" src={supportIcon}/>
                    <span>
                        <p>Central telefónica 2</p>
                        <p><small>2257 9924</small></p>
                    </span>
                    <CallRegular fontSize={24} className="phonebook-action"/>
                </a>
                <a className="phonebook-item" href="tel:22579932">
                    <img className="phonebook-photo" src={supportIcon}/>
                    <span>
                        <p>Central telefónica 3</p>
                        <p><small>2257 9932</small></p>
                    </span>
                    <CallRegular fontSize={24} className="phonebook-action"/>
                </a>
                <a className="phonebook-item" href="tel:22579951">
                    <img className="phonebook-photo" src={supportIcon}/>
                    <span>
                        <p>Central telefónica 4</p>
                        <p><small>2257 9951</small></p>
                    </span>
                    <CallRegular fontSize={24} className="phonebook-action"/>
                </a>
                <a className="phonebook-item" href="tel:22579959">
                    <img className="phonebook-photo" src={supportIcon}/>
                    <span>
                        <p>Central telefónica 5</p>
                        <p><small>2257 9959</small></p>
                    </span>
                    <CallRegular fontSize={24} className="phonebook-action"/>
                </a>
            </div>

            <button className="accordion">
                <BookCoinsRegular fontSize={24} className="accordion-icon" />
                Unidad de Relaciones Políticas
                {/* <ChevronDownRegular fontSize={24} className="accordion-expand" /> */}
            </button>
            <div className="panel">
                <a className="phonebook-item" href="tel:22578233,2012">
                    <img className="phonebook-photo" src={profileIcon}/>
                    <span>
                        <p>Albino Vargas Barrantes</p>
                        <p><small>Ext. 2012</small></p>
                    </span>
                    <CallRegular fontSize={24} className="phonebook-action"/>
                </a>
                <a className="phonebook-item" href="tel:22579924,2004">
                    <img className="phonebook-photo" src={profileIcon}/>
                    <span>
                        <p>Alonso Meza Chávez</p>
                        <p><small>Ext.2004</small></p>
                    </span>
                    <CallRegular fontSize={24} className="phonebook-action"/>
                </a>
                <a className="phonebook-item" href="tel:22579924,2004">
                    <img className="phonebook-photo" src={profileIcon}/>
                    <span>
                        <p>Kenlly Urtecho Obando</p>
                        <p><small>Ext.2004</small></p>
                    </span>
                    <CallRegular fontSize={24} className="phonebook-action"/>
                </a>
                <a className="phonebook-item" href="tel:22579924,2024">
                    <img className="phonebook-photo" src={profileIcon}/>
                    <span>
                        <p>Rafael Mora Solano</p>
                        <p><small>Ext.2024</small></p>
                    </span>
                    <CallRegular fontSize={24} className="phonebook-action"/>
                </a>
                <a className="phonebook-item" href="tel:22579924">
                    <img className="phonebook-photo" src={profileIcon}/>
                    <span>
                        <p>Roberto Zeledón Arias</p>
                        <p><small></small></p>
                    </span>
                    <CallRegular fontSize={24} className="phonebook-action"/>
                </a>
                <a className="phonebook-item" href="tel:22579924,2013">
                    <img className="phonebook-photo" src={profileIcon}/>
                    <span>
                        <p>Walter Quesada Fernández</p>
                        <p><small>Ext.2013</small></p>
                    </span>
                    <CallRegular fontSize={24} className="phonebook-action"/>
                </a>
            </div>

            <button className="accordion">
                <BookCoinsRegular fontSize={24} className="accordion-icon" />
                Unidad de Capacitación y Formación
                {/* <ChevronDownRegular fontSize={24} className="accordion-expand" /> */}
            </button>
            <div className="panel">
                <a className="phonebook-item" href="tel:22578233,2015">
                    <img className="phonebook-photo" src={profileIcon}/>
                    <span>
                        <p>María Laura Sánchez Rojas</p>
                        <p><small>Ext. 2015</small></p>
                    </span>
                    <CallRegular fontSize={24} className="phonebook-action"/>
                </a>
            </div>

            <button className="accordion">
                <BookCoinsRegular fontSize={24} className="accordion-icon" />
                Unidad de Desarrollo organizacional
                {/* <ChevronDownRegular fontSize={24} className="accordion-expand" /> */}
            </button>
            <div className="panel">
                <a className="phonebook-item" href="tel:22579924,2038">
                    <img className="phonebook-photo" src={profileIcon}/>
                    <span>
                        <p>Mainor Anchía Angulo</p>
                        <p><small>Ext. 2038</small></p>
                    </span>
                    <CallRegular fontSize={24} className="phonebook-action"/>
                </a>
                <a className="phonebook-item" href="tel:22579924,2048">
                    <img className="phonebook-photo" src={profileIcon}/>
                    <span>
                        <p>Jonathan Flores Mata</p>
                        <p><small>Ext. 2048</small></p>
                    </span>
                    <CallRegular fontSize={24} className="phonebook-action"/>
                </a>
                <a className="phonebook-item" href="tel:22579924,2026">
                    <img className="phonebook-photo" src={profileIcon}/>
                    <span>
                        <p>Álvaro Valderde Granados</p>
                        <p><small>Ext. 2026</small></p>
                    </span>
                    <CallRegular fontSize={24} className="phonebook-action"/>
                </a>
                <a className="phonebook-item" href="tel:22579924,2034">
                    <img className="phonebook-photo" src={profileIcon}/>
                    <span>
                        <p>Antonio Ortíz Fiorabanti</p>
                        <p><small>Ext.2034</small></p>
                    </span>
                    <CallRegular fontSize={24} className="phonebook-action"/>
                </a>
                <a className="phonebook-item" href="tel:22579924,2034">
                    <img className="phonebook-photo" src={profileIcon}/>
                    <span>
                        <p>Benjamin Sevilla García</p>
                        <p><small>Ext. 2034</small></p>
                    </span>
                    <CallRegular fontSize={24} className="phonebook-action"/>
                </a>
                <a className="phonebook-item" href="tel:22579924,2047">
                    <img className="phonebook-photo" src={profileIcon}/>
                    <span>
                        <p>Jorge Campos Alfaro</p>
                        <p><small>Ext. 2047</small></p>
                    </span>
                    <CallRegular fontSize={24} className="phonebook-action"/>
                </a>
                <a className="phonebook-item" href="tel:22579924,2036">
                    <img className="phonebook-photo" src={profileIcon}/>
                    <span>
                        <p>Claudio Sánchez González</p>
                        <p><small>Ext. 2036</small></p>
                    </span>
                    <CallRegular fontSize={24} className="phonebook-action"/>
                </a>
                <a className="phonebook-item" href="tel:22579924,2044">
                    <img className="phonebook-photo" src={profileIcon}/>
                    <span>
                        <p>Daniel Castañeda Chinchilla</p>
                        <p><small>Ext. 2044</small></p>
                    </span>
                    <CallRegular fontSize={24} className="phonebook-action"/>
                </a>
                <a className="phonebook-item" href="tel:22579924,2044">
                    <img className="phonebook-photo" src={profileIcon}/>
                    <span>
                        <p>Deiby Porras Arias</p>
                        <p><small>Ext. 2044</small></p>
                    </span>
                    <CallRegular fontSize={24} className="phonebook-action"/>
                </a>
                <a className="phonebook-item" href="tel:22579924,2027">
                    <img className="phonebook-photo" src={profileIcon}/>
                    <span>
                        <p>Edwin Marín Bonilla</p>
                        <p><small>Ext. 2027</small></p>
                    </span>
                    <CallRegular fontSize={24} className="phonebook-action"/>
                </a>
                <a className="phonebook-item" href="tel:22579924,2019">
                    <img className="phonebook-photo" src={profileIcon}/>
                    <span>
                        <p>Esteban Quesada Chacón</p>
                        <p><small>Ext. 2019</small></p>
                    </span>
                    <CallRegular fontSize={24} className="phonebook-action"/>
                </a>
                <a className="phonebook-item" href="tel:22579924,2033">
                    <img className="phonebook-photo" src={profileIcon}/>
                    <span>
                        <p>Gerardo Cascante Amador</p>
                        <p><small>Ext. 2033</small></p>
                    </span>
                    <CallRegular fontSize={24} className="phonebook-action"/>
                </a>
                <a className="phonebook-item" href="tel:22579924,2039">
                    <img className="phonebook-photo" src={profileIcon}/>
                    <span>
                        <p>Gerardo Cascante Vargas</p>
                        <p><small>Ext. 2039</small></p>
                    </span>
                    <CallRegular fontSize={24} className="phonebook-action"/>
                </a>
                <a className="phonebook-item" href="tel:22579924,2008">
                    <img className="phonebook-photo" src={profileIcon}/>
                    <span>
                        <p>Giovanny Ramírez Guerrero</p>
                        <p><small>Ext. 2008</small></p>
                    </span>
                    <CallRegular fontSize={24} className="phonebook-action"/>
                </a>
                <a className="phonebook-item" href="tel:22579924,2028">
                    <img className="phonebook-photo" src={profileIcon}/>
                    <span>
                        <p>Pamela Rodríguez Boza</p>
                        <p><small>Ext. 2028</small></p>
                    </span>
                    <CallRegular fontSize={24} className="phonebook-action"/>
                </a>
                <a className="phonebook-item" href="tel:22579924,2044">
                    <img className="phonebook-photo" src={profileIcon}/>
                    <span>
                        <p>James Porras López</p>
                        <p><small>Ext. 2044</small></p>
                    </span>
                    <CallRegular fontSize={24} className="phonebook-action"/>
                </a>
                <a className="phonebook-item" href="tel:22579924,2040">
                    <img className="phonebook-photo" src={profileIcon}/>
                    <span>
                        <p>Javier Valerín Villegas</p>
                        <p><small>Ext. 2040</small></p>
                    </span>
                    <CallRegular fontSize={24} className="phonebook-action"/>
                </a>
                <a className="phonebook-item" href="tel:22579924,2034">
                    <img className="phonebook-photo" src={profileIcon}/>
                    <span>
                        <p>Ivannia Espinoza Sandoval</p>
                        <p><small>Ext. 2034</small></p>
                    </span>
                    <CallRegular fontSize={24} className="phonebook-action"/>
                </a>
                <a className="phonebook-item" href="tel:22579924,2031">
                    <img className="phonebook-photo" src={profileIcon}/>
                    <span>
                        <p>Keilyn Urtecho Obando</p>
                        <p><small>Ext. 2031</small></p>
                    </span>
                    <CallRegular fontSize={24} className="phonebook-action"/>
                </a>
                <a className="phonebook-item" href="tel:22579924,2035">
                    <img className="phonebook-photo" src={profileIcon}/>
                    <span>
                        <p>Ligia Solís Solís</p>
                        <p><small>Ext. 2035</small></p>
                    </span>
                    <CallRegular fontSize={24} className="phonebook-action"/>
                </a>
                <a className="phonebook-item" href="tel:22579924,2037">
                    <img className="phonebook-photo" src={profileIcon}/>
                    <span>
                        <p>Lorena Chavarría Martínez</p>
                        <p><small>Ext. 2037</small></p>
                    </span>
                    <CallRegular fontSize={24} className="phonebook-action"/>
                </a>
                <a className="phonebook-item" href="tel:22579924,2030">
                    <img className="phonebook-photo" src={profileIcon}/>
                    <span>
                        <p>Roy Chaverri Chacón</p>
                        <p><small>Ext. 2030</small></p>
                    </span>
                    <CallRegular fontSize={24} className="phonebook-action"/>
                </a>
                <a className="phonebook-item" href="tel:22330222">
                    <img className="phonebook-photo" src={supportIcon}/>
                    <span>
                        <p>Número Anexo 1</p>
                        <p><small>2233 0222</small></p>
                    </span>
                    <CallRegular fontSize={24} className="phonebook-action"/>
                </a>
                <a className="phonebook-item" href="tel:22220782">
                    <img className="phonebook-photo" src={supportIcon}/>
                    <span>
                        <p>Número Anexo 2</p>
                        <p><small>2222 0782</small></p>
                    </span>
                    <CallRegular fontSize={24} className="phonebook-action"/>
                </a>
            </div>

            <button className="accordion">
                <BookCoinsRegular fontSize={24} className="accordion-icon" />
                Unidad de Finanzas y Administración
                {/* <ChevronDownRegular fontSize={24} className="accordion-expand" /> */}
            </button>
            <div className="panel">
                <a className="phonebook-item" href="tel:22579924,208">
                    <img className="phonebook-photo" src={supportIcon}/>
                    <span>
                        <p>Contabilidad</p>
                        <p><small>Ext. 208</small></p>
                    </span>
                    <CallRegular fontSize={24} className="phonebook-action"/>
                </a>
                <a className="phonebook-item" href="tel:22579924,2000">
                    <img className="phonebook-photo" src={profileIcon}/>
                    <span>
                        <p>Cristina Ramírez Porras</p>
                        <p><small>Ext. 2000</small></p>
                    </span>
                    <CallRegular fontSize={24} className="phonebook-action"/>
                </a>
                <a className="phonebook-item" href="tel:22579924,2020">
                    <img className="phonebook-photo" src={profileIcon}/>
                    <span>
                        <p>Daniel Torres Ortega</p>
                        <p><small>Ext. 2020</small></p>
                    </span>
                    <CallRegular fontSize={24} className="phonebook-action"/>
                </a>
                <a className="phonebook-item" href="tel:22579924,2006">
                    <img className="phonebook-photo" src={profileIcon}/>
                    <span>
                        <p>Gerardina Morales Torres <b>(Departamento de Afiliación)</b></p>
                        <p><small>Ext.2006</small></p>
                    </span>
                    <CallRegular fontSize={24} className="phonebook-action"/>
                </a>
                <a className="phonebook-item" href="tel:22579924,2017">
                    <img className="phonebook-photo" src={profileIcon}/>
                    <span>
                        <p>Hellen Marín Sánchez <b>(Departamento de Afiliación)</b></p>
                        <p><small>Ext. 2017</small></p>
                    </span>
                    <CallRegular fontSize={24} className="phonebook-action"/>
                </a>
                <a className="phonebook-item" href="tel:22579924,2002">
                    <img className="phonebook-photo" src={profileIcon}/>
                    <span>
                        <p>Rusbell Masís Salazar  <b>(Departamento de Pólizas)</b></p>
                        <p><small>Ext. 2002</small></p>
                    </span>
                    <CallRegular fontSize={24} className="phonebook-action"/>
                </a>
                <a className="phonebook-item" href="tel:22579924,2009">
                    <img className="phonebook-photo" src={profileIcon}/>
                    <span>
                        <p>Martha Guido Serrano</p>
                        <p><small>Ext. 2009</small></p>
                    </span>
                    <CallRegular fontSize={24} className="phonebook-action"/>
                </a>
                <a className="phonebook-item" href="tel:22579924,2011">
                    <img className="phonebook-photo" src={profileIcon}/>
                    <span>
                        <p>Pablo Freer Paniagua</p>
                        <p><small>Ext. 2011</small></p>
                    </span>
                    <CallRegular fontSize={24} className="phonebook-action"/>
                </a>
                <a className="phonebook-item" href="tel:22579924,2001">
                    <img className="phonebook-photo" src={profileIcon}/>
                    <span>
                        <p>Rafael Gamboa Sandí</p>
                        <p><small>Ext. 2001</small></p>
                    </span>
                    <CallRegular fontSize={24} className="phonebook-action"/>
                </a>
            </div>

            <button className="accordion">
                <BookCoinsRegular fontSize={24} className="accordion-icon" />
                Unidad de Asesoría jurídica
                {/* <ChevronDownRegular fontSize={24} className="accordion-expand" /> */}
            </button>
            <div className="panel">
                <a className="phonebook-item" href="tel:22579924,2021">
                    <img className="phonebook-photo" src={profileIcon}/>
                    <span>
                        <p>David Estrada Zeledón</p>
                        <p><small>Ext. 2021</small></p>
                    </span>
                    <CallRegular fontSize={24} className="phonebook-action"/>
                </a>
                <a className="phonebook-item" href="tel:22579924,2018">
                    <img className="phonebook-photo" src={profileIcon}/>
                    <span>
                        <p>José Pablo Quirós Picado</p>
                        <p><small>Ext. 2018</small></p>
                    </span>
                    <CallRegular fontSize={24} className="phonebook-action"/>
                </a>
                <a className="phonebook-item" href="tel:22579924,2010">
                    <img className="phonebook-photo" src={profileIcon}/>
                    <span>
                        <p>Harry Flores Castillo</p>
                        <p><small>Ext. 2010</small></p>
                    </span>
                    <CallRegular fontSize={24} className="phonebook-action"/>
                </a>
                <a className="phonebook-item" href="tel:22330222">
                    <img className="phonebook-photo" src={profileIcon}/>
                    <span>
                        <p>Nicole Mannix Chacón</p>
                        <p><small>2233 0222</small></p>
                    </span>
                    <CallRegular fontSize={24} className="phonebook-action"/>
                </a>
                <a className="phonebook-item" href="tel:22579924,2022">
                    <img className="phonebook-photo" src={profileIcon}/>
                    <span>
                        <p>Karen Carvajal Loaiza</p>
                        <p><small>Ext. 2022</small></p>
                    </span>
                    <CallRegular fontSize={24} className="phonebook-action"/>
                </a>
                <a className="phonebook-item" href="tel:22579924,2014">
                    <img className="phonebook-photo" src={profileIcon}/>
                    <span>
                        <p>Maria del Mar López Delgado</p>
                        <p><small>Ext. 2014</small></p>
                    </span>
                    <CallRegular fontSize={24} className="phonebook-action"/>
                </a>
                <a className="phonebook-item" href="tel:22220782">
                    <img className="phonebook-photo" src={profileIcon}/>
                    <span>
                        <p>Gustavo Hernández Picado</p>
                        <p><small>2222 0782</small></p>
                    </span>
                    <CallRegular fontSize={24} className="phonebook-action"/>
                </a>
                <a className="phonebook-item" href="tel:22579924,2029">
                    <img className="phonebook-photo" src={profileIcon}/>
                    <span>
                        <p>Pricilla Castro Jiménez</p>
                        <p><small>Ext. 2029</small></p>
                    </span>
                    <CallRegular fontSize={24} className="phonebook-action"/>
                </a>
                <a className="phonebook-item" href="tel:22579924,2005">
                    <img className="phonebook-photo" src={profileIcon}/>
                    <span>
                        <p>Rony Flores Espinoza</p>
                        <p><small>Ext. 2005</small></p>
                    </span>
                    <CallRegular fontSize={24} className="phonebook-action"/>
                </a>
                <a className="phonebook-item" href="tel:22579924,2023">
                    <img className="phonebook-photo" src={profileIcon}/>
                    <span>
                        <p>Rosa Rivera Bejarano</p>
                        <p><small>Ext. 2023</small></p>
                    </span>
                    <CallRegular fontSize={24} className="phonebook-action"/>
                </a>
                <a className="phonebook-item" href="tel:22579924,2025">
                    <img className="phonebook-photo" src={profileIcon}/>
                    <span>
                        <p>Carolina Zúñiga Castro</p>
                        <p><small>Ext. 2025</small></p>
                    </span>
                    <CallRegular fontSize={24} className="phonebook-action"/>
                </a>
            </div>

            <button className="accordion">
                <BookCoinsRegular fontSize={24} className="accordion-icon" />
                Unidad de Información y Comunicación
                {/* <ChevronDownRegular fontSize={24} className="accordion-expand" /> */}
            </button>
            <div className="panel">
                <a className="phonebook-item" href="tel:22579924,2046">
                    <img className="phonebook-photo" src={profileIcon}/>
                    <span>
                        <p>Eugenio Guerrero Cascante</p>
                        <p><small>Ext. 2046</small></p>
                    </span>
                    <CallRegular fontSize={24} className="phonebook-action"/>
                </a>
                <a className="phonebook-item" href="tel:22579924,2046">
                    <img className="phonebook-photo" src={profileIcon}/>
                    <span>
                        <p>Yariela Novo Cárdenas</p>
                        <p><small>Ext. 2046</small></p>
                    </span>
                    <CallRegular fontSize={24} className="phonebook-action"/>
                </a>
                <a className="phonebook-item" href="tel:22579924,2046">
                    <img className="phonebook-photo" src={profileIcon}/>
                    <span>
                        <p>Carlos Esteban Ortíz Forero</p>
                        <p><small>Ext. 2046</small></p>
                    </span>
                    <CallRegular fontSize={24} className="phonebook-action"/>
                </a>
            </div>

        </div>
    );
}