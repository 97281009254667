import './Inicio.css';
import { collection, doc, getDoc, getDocs, getFirestore, limit, orderBy, query, where, Timestamp, addDoc, connectFirestoreEmulator, onSnapshot, setDoc } from 'firebase/firestore';
import { getMessaging, onMessage, getToken, isSupported as isMessagingSupported } from "firebase/messaging";
import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import Post from '../components/Post';
import googleIcon from '../assets/google-icon.png';
import { getAuth, signOut, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import styles from './Calendario.module.css';

export default function Inicio({ authState, userId, userVerified, fullName, cedula, fcmToken }) {
    const [newsList, setNewsList] = useState([]);
    const [eventos, setEventos] = useState([]);

    console.log(authState)

    const db = getFirestore();
    const auth = getAuth();
    const messaging = getMessaging();

    useEffect(() => {
        const fetchData = async () => {
            const q = query(collection(db, "news"), orderBy("timestamp", "asc"), limit(1));
            const querySnapshot = await getDocs(q);
            var array = [];
            querySnapshot.forEach((doc) => {
                array.push({
                    id: doc.id,
                    title: doc.data().title,
                    description: doc.data().description,
                    thumbnail: doc.data().thumbnail,
                    likesCounter: doc.data().likesCounter,
                    timestamp: doc.data().timestamp.seconds
                });
            });
            console.log(array);
            setNewsList(array);
        }
        fetchData();

    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const now = new Date();
                const q = query(collection(db, "calendario"), where("inicio", ">=", now), limit(3));
                const querySnapshot = await getDocs(q);

                const newEventos = querySnapshot.docs.map(doc => {
                    const data = doc.data();
                    const titulo = data.titulo || "Titulo no definido";
                    const descripcion = data.descripcion || "Este evento no tiene descripcion";
                    const categoria = data.categoria || "Sin Categoria";
                    const inicio = data.inicio?.toDate() || new Date();
                    return { id: doc.id, titulo, descripcion, categoria, inicio };
                });

                console.log(newEventos);
                setEventos(newEventos);
            } catch (error) {
                console.error("Error getting documents: ", error);
            }
        };

        fetchData();
    }, []);


    const cerrarSesion = () => {
        signOut(auth).then(() => {
            // Sign-out successful.
            alert('Sign-out successful.');
        }).catch((error) => {
            // An error happened.
            alert('An error happened.');
        });
    }

    const list = newsList.map(data =>
        <Post
            key={data.id}
            id={data.id}
            title={data.title}
            description={data.description}
            thumbnail={data.thumbnail}
            likesCounter={data.likesCounter}
            userId={userId}
            authState={authState}
        />
    )

    const googleSignUp = () => {
        const provider = new GoogleAuthProvider();
        signInWithPopup(auth, provider)
            .then(async (result) => {
                console.log(result);

                try {
                    if (!isMessagingSupported()) return;

                    let currentToken = await getToken(messaging, { vapidKey: 'BOt_s76ybS7GKWbCTDWJ-dAzGZK4GS9c0GMOAg6Alm6F1xJhPSsyDeEmrGGY47Fj409t20BLknQYGJhcdc0xiC4' });

                    if (!currentToken && await Notification.requestPermission() === 'granted') {
                        currentToken = await getToken(messaging, { vapidKey: 'BOt_s76ybS7GKWbCTDWJ-dAzGZK4GS9c0GMOAg6Alm6F1xJhPSsyDeEmrGGY47Fj409t20BLknQYGJhcdc0xiC4' });
                    }

                    if (currentToken) {
                        console.log('current token for client: ', currentToken);
                        const docRef = doc(db, "fcmTokens", result.user.uid);
                        const payload = { token: currentToken, timestamp: Timestamp.now() };
                        await setDoc(docRef, payload, { merge: true });
                        console.log('fcmToken added to document', result.user.uid);
                    } else {
                        throw new Error('No registration token available. Request permission to generate one.');
                    }
                } catch (error) {
                    // alert('Hubo un error al suscribirte al caso.', error);
                    console.log('An error occurred while retrieving token. ', error);
                }


            })
            .catch((error) => {
                console.log(error);
                const errorMessage = error.message;
                alert(errorMessage);
            });
    };

    return (
        <div>

            <h1 className='display-small' style={{ margin: '0 1.5rem' }}>ANEP</h1>


            {
                authState === null &&
                <div className='banner'>
                    CARGANDO...
                </div>
            }

            {
                authState === false &&
                <div className='banner'>
                    <span className='material-symbols'>
                        badge
                    </span>
                    <h2 className='headline-small'>Inicia sesión</h2>
                    <p>Para continuar con la vinculación de tu afiliación</p>
                    {/* <Link to='/signup'>
                            <button className='outlined-button'>Continuar con Google</button>
                        </Link> */}

                    <button className="outlined-button" onClick={googleSignUp} style={{ background: 'white', color: 'black' }}>
                        <img src={googleIcon} alt="google icon" />
                        Continuar with Google
                    </button>

                </div>
            }

            {
                authState === true && !userVerified &&
                <div className='banner'>
                    <span class="material-symbols">
                        dataset_linked
                    </span>
                    <h2 className='headline-small'>Vincula tu cuenta a tu afiliacion ANEP</h2>
                    <p>Si tienes una afiliación activa, continua con la vinculación de tu cuenta.</p>

                    <Link to="signup/vincular">
                        <button className='filled-tonal-button' style={{ float: 'right', width: '100%' }}><span className='material-symbols'>
                            link
                        </span> Vincular mi afiliacion</button>
                    </Link>

                </div>
            }

            {
                authState === true && userVerified === true &&
                <div className='carnet'>
                    <div className='carnet-header'>
                        <h2 className='title-medium'>Carnet</h2>
                        <span className='title-large'>ANEP</span>
                    </div>

                    <div>
                        <h3 className='title-medium' style={{ fontWeight: '700' }}>
                            <code>
                                {fullName}
                            </code>
                        </h3>
                        <span className='title-small'>{cedula}</span>
                    </div>

                    <Link to='carne'>
                        <button className='outlined-button' style={{ color: 'white', width: 'fit-content', borderColor: 'white' }}>
                            <span className='material-symbols'>
                                qr_code
                            </span>
                            Abrir QR
                        </button>
                    </Link>

                </div>
            }

            {/* {
                (authState === false) ?
                    <div className='banner'>
                        <span className='material-symbols'>
                            badge
                        </span>
                        <h2 className='headline-small'>Inicia sesión</h2>
                        <p>Para continuar con la vinculación de tu afiliación</p>

                        <button className="outlined-button" onClick={googleSignUp}>
                            <img src={googleIcon} alt="google icon" />
                            Continuar with Google
                        </button>

                    </div> : (authState === true && userVerified === false) ?
                        <div className='banner'>
                            <span class="material-symbols">
                                dataset_linked
                            </span>
                            <h2 className='headline-small'>Vincula tu cuenta a tu afiliacion ANEP</h2>
                            <p>Si tienes una afiliación activa, continua con la vinculación de tu cuenta. Si aún no eres afiliado, solicita tu afiliación.</p>

                            <Link to="signup/vincular">
                                <button className='filled-button' style={{ float: 'right', width: '100%' }}><span className='material-symbols'>
                                    link
                                </span> Vincular mi afiliacion existente</button>
                            </Link>

                        </div> : (authState === true && userVerified === true) ?
                            <div className='banner'>
                                <h4>{fullName}</h4>
                            </div> :
                            <div className='banner' />
            } */}

            <section className='home-cards-section'>
                <Link to="poliza" className='home-card' state={{ title: 'Pólizas de vida' }}>
                    <span className='material-symbols'>
                        shield_person
                    </span>
                    <p className='label-large'>Pólizas</p>
                </Link>
                <Link to="legal" className='home-card' state={{ title: 'Departamento legal' }}>
                    <span className='material-symbols'>
                        cases
                    </span>
                    <p className='label-large'>Legal</p>
                </Link>
                <Link to="recreacion" className='home-card' state={{ title: 'Centro recreativo' }}>
                    <span className='material-symbols'>
                        beach_access
                    </span>
                    <p className='label-large'>Centro recreativo</p>
                </Link>
                <Link to="convenios" className='home-card' state={{ title: 'Beneficios' }}>
                    <span className='material-symbols'>
                        loyalty
                    </span>
                    <p className='label-large'>Beneficios</p>
                </Link>
            </section>

            <section style={{
                margin: '1.5rem',
            }}>
                <p style={{ marginBottom: '1rem', opacity: '0.7' }}>Próximo en el calendario: </p>
                {
                    eventos.map(evento => (
                        <Event key={evento.id} titulo={evento.titulo} categoria={evento.categoria} inicio={evento.inicio} />
                    ))
                }

            </section>


            {/* <h2>Beneficios</h2>

            <Link to={{ pathname: "/legal" }} state={{ title: 'Convenios' }}>
                <div className='home-benefit-card'>
                    <img src={justice} />
                    <div>
                        <h5>Dale seguimiento a tus casos legales</h5>
                        <p>
                            Consulta el estado de tus casos legales en ANEP.
                        </p>
                    </div>
                </div>
            </Link>


            <Link to={{ pathname: "/convenios" }} state={{ title: 'Convenios' }}>
                <div className='home-benefit-card'>
                    <img src={convenio} />
                    <div>
                        <h5>Convenios comerciales</h5>
                        <p>Promociones exclusivas para afiliados a la ANEP. </p>
                    </div>
                </div>
            </Link>

            <Link to={{ pathname: "/poliza" }} state={{ title: 'Directorio' }}>
                <div className='home-benefit-card'>
                    <img src={insurance} />
                    <div>
                        <h5>Poliza</h5>
                        <p>La afiliación de ANEP tiene la posibilidad de suscribirse a pólizas colectiva de vida.</p>
                    </div>
                </div>
            </Link>

            <Link to={{ pathname: "/recreacion" }} state={{ title: 'C. Recreativo' }}>
                <div className='home-benefit-card'>
                    <img src={manuela} />
                    <div>
                        <h5>Centro recreativo</h5>
                        <p>Cabinas propiedad de la organización ubicadas en esta paradisíaca playa. </p>
                    </div>
                </div>
            </Link> */}


            {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <h4>Última publicación</h4>
                <Link to="/noticias"><button className='text-button'>Ver todos</button></Link>
            </div>
            {list} */}

            <button className='outlined-button' onClick={cerrarSesion} style={{ margin: '1.5rem' }}>Cerrar sesion</button>
        </div>
    );
}

function Event({ id, titulo, descripcion, categoria, inicio }) {
    function formatDate(date) {
        const options = { month: 'short', day: '2-digit' };
        return date.toLocaleDateString('es-ES', options).toUpperCase();
    }

    function getClassName(categoria) {
        switch (categoria) {
            case 'feriado':
                return styles.feriado;
            case 'pago':
                return styles.pago;
            case 'asamblea':
                return styles.asamblea;
            case 'celebracion':
                return styles.celebracion;
            default:
                return '';
        }
    }

    return (
        <div className={`${styles.event} ${getClassName(categoria)}`}>
            <div>
                <p className='title-medium'>{titulo}</p>
                <span className='label-medium'>{categoria.toUpperCase()}</span>
            </div>
            <span className='label-medium'>{formatDate(inicio)}</span>
        </div>
    );
}