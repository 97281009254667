import './NavigationDrawer.css';
import {
    HomeRegular,
    HomeFilled,
    NewsRegular,
    NewsFilled,
    TagRegular,
    TagFilled,
    PersonRegular,
    PersonFilled,
    CallRegular,
    CallFilled,
    BriefcaseRegular,
    BriefcaseFilled,
    ShieldTaskRegular,
    ShieldTaskFilled
} from "@fluentui/react-icons";
import {
    Link,
    useMatch,
    useResolvedPath
} from "react-router-dom";

export default function NavigationDrawer() {
    return (
        <nav className='navigation-drawer' id='navigation-drawer'>

            {/* <h5>Navigation</h5> */}

            <CustomLink
                to="/"
                state={{ title: 'ANEP' }}
                icon={
                    <span className='material-symbols'>
                        home
                    </span>
                }
                activeicon={
                    <span className='material-symbols active'>
                        home
                    </span>
                }>
                Home
            </CustomLink>

            <CustomLink
                to="noticias"
                state={{ title: 'Noticias' }}
                icon={<NewsRegular fontSize={24} />}
                activeicon={<NewsFilled fontSize={24} />}>
                Noticias
            </CustomLink>

            <CustomLink
                to="convenios"
                state={{ title: 'Convenios' }}
                icon={<TagRegular fontSize={24} />}
                activeicon={<TagFilled fontSize={24} />}>
                Convenios
            </CustomLink>

            <CustomLink
                to="carne"
                state={{ title: 'Carné' }}
                icon={<PersonRegular fontSize={24} />}
                activeicon={<PersonFilled fontSize={24} />}>
                Carne
            </CustomLink>

            <hr style={{ margin: '1rem  0', borderColor: 'var(--outline)' }}></hr>

            <CustomLink
                to="poliza"
                state={{ title: 'Polizas' }}
                icon={<ShieldTaskRegular fontSize={24} />}
                activeicon={<ShieldTaskFilled fontSize={24} />}>
                Polizas
            </CustomLink>

            {/* <CustomLink 
                to="calendario" 
                state={{title: 'enotar.io'}}
                icon={<HomeRegular fontSize={24} />} 
                activeicon={<HomeFilled fontSize={24} />}
                onClick={closeOnLink}>
                Calendario
            </CustomLink> */}

            {/* <CustomLink
                to="directorio" 
                state={{title: 'Directorio'}}
                icon={<CallRegular fontSize={24} />} 
                activeicon={<CallFilled fontSize={24} />}>
                Directorio
            </CustomLink> */}

            {/* <CustomLink
                to="legal" 
                state={{title: 'Legal'}}
                icon={<BriefcaseRegular fontSize={24} />} 
                activeicon={<BriefcaseFilled fontSize={24} />}>
                Legal
            </CustomLink> */}

            {/* <CustomLink
                to="afiliate" 
                state={{title: 'Afiliate'}}
                icon={<InfoRegular fontSize={24} />} 
                activeicon={<InfoFilled fontSize={24} />}
                onClick={closeOnLink}>
                Afiliate
            </CustomLink> */}


            {/* <hr style={{margin: '1rem  0', borderColor: 'var(--outline)'}}></hr> */}


        </nav>
    )
};

function CustomLink({ children, to, ...props }) {
    let resolved = useResolvedPath(to);
    let match = useMatch({ path: resolved.pathname, end: true });

    return (
        <Link to={to} {...props} className={match ? "navigation-drawer-item navigation-drawer-item-match" : "navigation-drawer-item"}>
            {match ? props.activeicon : props.icon}
            {children}
        </Link>
    );
}