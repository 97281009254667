import './Recreacion.css';
import manuela from '../assets/manuela.jpeg';
import { CallRegular } from "@fluentui/react-icons";

export default function Recreacion () {
    return(
        <div className="recreational">
            <img src={manuela} className="recreational-img" />
            <div className="recreational-content">
                <h1 className='headline-large'>Centro recreativo Manuel Antonio</h1>
                <p>A tan solo 3 horas de San José las personas afiliadas a la ANEP pueden disfrutar de las bellezas naturales  que ofrece Manuel Antonio, gracias a las cabinas propiedad de la organización ubicadas en esta paradisíaca playa del pacifico central  costarricense.</p>
                <h4>Para mas informacion llamar al: </h4>
                <button className="filled-button" style={{marginTop: '1rem'}} onClick={() => { window.location.href = 'tel:+50622578233' }}><CallRegular fontSize={24} />2257 8233</button>
            </div>
        </div>
    )
}