import { useEffect, useState } from "react";
import { collection, doc, getDoc, getDocs, getFirestore, limit, orderBy, query, where, Timestamp, addDoc, connectFirestoreEmulator, onSnapshot, setDoc } from 'firebase/firestore';
import { getMessaging, onMessage, getToken, isSupported as isMessagingSupported } from "firebase/messaging";
import { Link } from "react-router-dom";
import styles from './CasosLegales.module.css';
import legalHero from '../assets/legal-hero.jpg';

export default function CasosLegales({ cedula, institucion }) {
    const [notificationEnabled, setNotificationEnabled] = useState(false);
    const [casos, setCasos] = useState([]);
    const [casosColectivos, setCasosColectivos] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [fcmToken, setFcmToken] = useState('');

    const db = getFirestore();
    const messaging = getMessaging();

    useEffect(() => {
        console.log(cedula);
        const fetchData = () => {
            var q = query(collection(db, "legales"), where("afiliado", "==", cedula));
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                var casos = [];
                querySnapshot.forEach((doc) => {
                    console.log(doc.id, " => ", doc.data());
                    casos.push({
                        id: doc.id,
                        ...doc.data()
                    });
                });
                setCasos(casos);
                console.log(casos);
            });

            // Clean up the listener when the component unmounts
            return () => unsubscribe();
        }
        fetchData();
    }, [cedula]);

    useEffect(() => {
        const fetchData = () => {
            var q = query(collection(db, "legales"), where("tipo", "==", 'COLECTIVO'), where("institucion", "==", institucion));

            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                var casos = [];
                querySnapshot.forEach((doc) => {
                    console.log(doc.id, " => ", doc.data());
                    casos.push({
                        id: doc.id,
                        ...doc.data()
                    });
                });
                setCasosColectivos(casos);
                console.log(casos);
            });

            // Clean up the listener when the component unmounts
            return () => unsubscribe();
        }
        fetchData();
    }, [institucion]);


    useEffect(() => {

        if (isMessagingSupported()) {
            getToken(messaging, { vapidKey: 'BOt_s76ybS7GKWbCTDWJ-dAzGZK4GS9c0GMOAg6Alm6F1xJhPSsyDeEmrGGY47Fj409t20BLknQYGJhcdc0xiC4' })
                .then((currentToken) => {
                    if (currentToken) {
                        console.log('current token for client: ', currentToken);
                        setFcmToken(currentToken);
                        // Track the token -> send it to your server via HTTP/HTTPS request
                    } else {
                        setErrorMessage('No registration token available. Request permission to generate one.');
                        // Show permission request.
                    }
                }).catch((err) => {
                    console.log('An error occurred while retrieving token. ', err);
                    // Check if the token was not found and ignore the error if so
                    if (err.code !== 'messaging/token-unsubscribe-failed') {
                        // Handle other potential errors
                    }
                });
        } else {
            setErrorMessage('Messaging not supported');
        }

    }, []);

    const requestPushNotificationPermission = async () => {
        try {
            const permission = await Notification.requestPermission();
            if (permission === 'granted') {
                console.log('Notification permission granted.');
                setNotificationEnabled(true);


                if (isMessagingSupported()) {
                    getToken(messaging, { vapidKey: 'BOt_s76ybS7GKWbCTDWJ-dAzGZK4GS9c0GMOAg6Alm6F1xJhPSsyDeEmrGGY47Fj409t20BLknQYGJhcdc0xiC4' })
                        .then((currentToken) => {
                            if (currentToken) {
                                console.log('current token for client: ', currentToken);
                                setFcmToken(currentToken);
                                // Track the token -> send it to your server via HTTP/HTTPS request
                            } else {
                                setErrorMessage('No registration token available. Request permission to generate one.');
                                // Show permission request.
                            }
                        }).catch((err) => {
                            console.log('An error occurred while retrieving token. ', err);
                            // Check if the token was not found and ignore the error if so
                            if (err.code !== 'messaging/token-unsubscribe-failed') {
                                // Handle other potential errors
                            }
                        });
                } else {
                    setErrorMessage('Messaging not supported');
                }


            } else {
                console.log('Unable to get permission to notify.');
                setNotificationEnabled(false);
            }
            // console.log('Notification permission status:', permission);

        } catch (error) {
            console.error(error);
        }
    }

    const testNotification = async () => {
        try {
            console.log('Checking service worker registration...');
            setErrorMessage('Checking service worker registration...');
            if (!('serviceWorker' in navigator)) {
                console.log('Service Worker not supported');
                setErrorMessage('Service Worker not supported');
                return;
            }
            console.log('Registering service worker...');
            setErrorMessage('Registering service worker...');
            const registration = await navigator.serviceWorker.ready;
            console.log('Service Worker registered successfully');
            setErrorMessage('Service Worker registered successfully');
            // set timeout to show notification after 5 seconds
            setTimeout(() => {
                console.log('Sending notification...');
                setErrorMessage('Sending notification...');
                registration.showNotification('Test Notification', {
                    body: 'This is a test notification',
                    icon: '/logo192.png'
                });
                console.log('Notification sent successfully');
                setErrorMessage('Notification sent successfully')
            }, 5000);
            // console.log('Notification sent');
        } catch (error) {
            setErrorMessage('Failed to show notification:');
            console.error('Failed to show notification:', error);
        }
    }

    let categories = {
        "A": "A - Contencioso Administrativo",
        "B": "B - Reclamos administrativos",
        "C": "C - Acciones de inconstitucionalidad",
        "D": "D - Administrativo disciplinario"
    };

    let glossary = {
        "A1": "A1 - Demanda",
        "A2": "A2 - Traslado de demanda",
        "A3": "A3 - Audiencia",
        "A4": "A4 - Sentencia primera instancia con lugar",
        "A5": "A5 - Sentencia de primera instancia sin lugar",
        "A6": "A6 - Sentencia segunda instancia con lugar",
        "A7": "A7 - Sentencia segunda instancia sin lugar",
        "A8": "A8 - Ejecución de sentencia",
        "A9": "A9 - Sentencia de ejecución primera instancia",
        "A10": "A10 - Sentencia de ejecución de segunda instancia",
        "B1": "B1 - Se presento",
        "B2": "B2 - Resolución",
        "B3": "B3 - Recurso",
        "C1": "C1 - Interposición",
        "C2": "C2 - Contestación",
        "C3": "C3 - Sentencia",
        "D1": "D1 - Traslado de cargos",
        "D2": "D2 - Audiencia",
        "D3": "D3 - Absuelto",
        "D4": "D4 - Sanción",
        "D5": "D5 - Despido"
    };

    const suscribrirme = async (caso) => {
        try {
            const docRef = doc(db, "legales", caso);
            const payload = {
                fcmToken: fcmToken
            };
            await setDoc(docRef, payload, { merge: true });
            console.log('fcmToken added to document');
        } catch (error) {
            console.error(error);
        }
    }

    const subscribe = async (caso) => {
        try {
            if (!isMessagingSupported()) return;

            let currentToken = await getToken(messaging, { vapidKey: 'BOt_s76ybS7GKWbCTDWJ-dAzGZK4GS9c0GMOAg6Alm6F1xJhPSsyDeEmrGGY47Fj409t20BLknQYGJhcdc0xiC4' });

            if (!currentToken && await Notification.requestPermission() === 'granted') {
                currentToken = await getToken(messaging, { vapidKey: 'BOt_s76ybS7GKWbCTDWJ-dAzGZK4GS9c0GMOAg6Alm6F1xJhPSsyDeEmrGGY47Fj409t20BLknQYGJhcdc0xiC4' });
            }

            if (currentToken) {
                setFcmToken(currentToken);
                console.log('current token for client: ', currentToken);
                const docRef = doc(db, "legales", caso);
                const payload = { fcmToken: currentToken };
                await setDoc(docRef, payload, { merge: true });
                console.log('fcmToken added to document');
            } else {
                throw new Error('No registration token available. Request permission to generate one.');
            }
        } catch (error) {
            alert('Hubo un error al suscribirte al caso.', error);
            console.log('An error occurred while retrieving token. ', error);
        }
    }

    return (
        <div>
            <h1 className="display-small" style={{ margin: '0 1.5rem' }}>Departamento legal</h1>

            <img className={styles.banner} src={legalHero} alt="Abogado" />

            <p>
                {errorMessage}
            </p>
            {/* <code>
                {fcmToken}
            </code> */}


            {/* <small>
                {fcmToken.slice(0, 10)}
            </small> */}


            <section className={styles.casos_section}>
                <h2 className="headline-medium">Mis casos</h2>

                <p style={{ opacity: '0.6' }} className="label-large">
                    Desde aquí puedes dar seguimiento a tus casos legales, recibir notificaciones y conversar con nuestros abogados.
                </p>

                {
                    casos.length === 0 &&

                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        textAlign: 'center',
                        margin: '4rem 0',
                        gap: '1rem',
                        opacity: '0.7'
                    }}>
                        <span className="material-symbols" style={{ fontSize: '32px' }}>gavel</span>
                        <p>No tienes casos legales, si necesitas ayuda legal-laboral puedes contactarnos para abrir tu caso legal y darle seguimiento en esta sección.</p>
                    </div>
                }

                {casos.map((caso) => {
                    return (
                        <div className={styles.caso} key={caso.id}>

                            {
                                (caso.accion === 'AFILIADO') ?
                                    <b className="label-small" style={{ color: 'var(--color-error)' }}>
                                        Requiere acción de tu parte
                                    </b>
                                    :
                                    <p className="label-small">
                                        No requiere acción de tu parte
                                    </p>
                            }

                            <h2 className={`${styles.tipo} title-medium`} style={{ marginTop: '1rem' }}>{categories[caso.tipo]}</h2>

                            <h5 className="label-medium">{glossary[caso.proceso]}</h5>

                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                marginTop: '1rem'
                            }}>

                                {
                                    (caso.fcmToken === fcmToken) ?
                                        <span className="material-symbols active" style={{ color: 'var(--color-tertiary)' }}>notifications_active</span> :
                                        <button className="text-button" onClick={() => subscribe(caso.id)} style={{ margin: '0 0 -.75rem -.75rem' }}>
                                            Recibir notificaciones
                                        </button>
                                }

                                <Link to={`/legal/chat?caso=${caso.id}`}>
                                    <button className="outlined-button">Ver caso</button>
                                </Link>

                            </div>


                        </div>
                    );
                })}
            </section>

            <section className={styles.casos_section}>
                <h2 className="headline-medium">Casos colectivos</h2>

                <p style={{ opacity: '0.6' }} className="label-large">
                    Desde aquí puedes dar seguimiento a los casos colectivos de tu institución.
                </p>

                {
                    casosColectivos.length === 0 &&

                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        textAlign: 'center',
                        margin: '4rem 0',
                        gap: '1rem',
                        opacity: '0.7'
                    }}>
                        <span className="material-symbols" style={{ fontSize: '32px' }}>gavel</span>
                        <p>No tienes casos legales, si necesitas ayuda legal-laboral puedes contactarnos para abrir tu caso legal y darle seguimiento en esta sección.</p>
                    </div>
                }

                {casosColectivos.map((caso) => {
                    return (
                        <div className={styles.caso} key={caso.id}>

                            {
                                (caso.accion === 'AFILIADO') ?
                                    <b className="label-small" style={{ color: 'var(--color-error)' }}>
                                        Requiere acción de tu parte
                                    </b>
                                    :
                                    <p className="label-small">
                                        No requiere acción de tu parte
                                    </p>
                            }

                            <h2 className={`${styles.tipo} title-medium`} style={{ marginTop: '1rem' }}>{categories[caso.tipo]}</h2>

                            <h5 className="label-medium">{glossary[caso.proceso]}</h5>

                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                marginTop: '1rem'
                            }}>

                                {
                                    (caso.fcmToken === fcmToken) ?
                                        <span className="material-symbols active" style={{ color: 'var(--color-tertiary)' }}>notifications_active</span> :
                                        <button className="text-button" onClick={() => subscribe(caso.id)} style={{ margin: '0 0 -.75rem -.75rem' }}>
                                            Recibir notificaciones
                                        </button>
                                }

                                <Link to={`/legal/chat?caso=${caso.id}`}>
                                    <button className="outlined-button">Ver caso</button>
                                </Link>

                            </div>


                        </div>
                    );
                })}
            </section>

            {/* {
                notificationEnabled ?
                    <p>Las notificaciones están habilitadas.</p> :
                    <p>Las notificaciones están deshabilitadas.</p>
            } */}

        </div>
    );
}