import './Noticias.css';
import { getFirestore, query, collection, orderBy, limit, getDocs } from "firebase/firestore";
import { useEffect, useState } from 'react';
import Post from '../components/Post';

export default function Noticias({ authState, userId }) {
    const [newsList, setNewsList] = useState([]);

    const db = getFirestore();

    useEffect(() => {
        const fetchData = async () => {
            const q = query(collection(db, "news"), orderBy("timestamp", "desc"), limit(10));
            const querySnapshot = await getDocs(q);
            var array = [];
            querySnapshot.forEach((doc) => {
                array.push({
                    id: doc.id,
                    title: doc.data().title,
                    description: doc.data().description,
                    thumbnail: doc.data().thumbnail,
                    likesCounter: doc.data().likesCounter,
                    timestamp: doc.data().timestamp.seconds,
                    url: doc.data().url

                });
            });
            console.log(array);
            setNewsList(array);
        }
        fetchData();
    }, []);

    const list = newsList.map(data =>
        <Post
            key={data.id}
            id={data.id}
            title={data.title}
            description={data.description}
            thumbnail={data.thumbnail}
            likesCounter={data.likesCounter}
            userId={userId}
            authState={authState}
            url={data.url}
        />
    )

    return (
        <div className='page'>
            <h1 className='display-small' style={{ marginBottom: '1.5rem' }}>Noticias</h1>
            {list}
        </div>
    );
}