import './Carne.css';
import QRCode from "react-qr-code";

export default function Carne({ cedula, fullName, authState, userVerified, institucion }) {
  return (
    <div className='page'>

      <h1 className='display-small'>Carnet</h1>
      <div className='carne-card'>
        <p className='headline-small' style={{display: 'flex', alignItems: 'center', gap: '0.5rem'}}>
          <span className='material-symbols'>
            badge
          </span>
          {cedula}</p>
        <QRCode title={'Afiliado'} bgColor="var(--color-background)" value={cedula} size={192} fgColor="var(--color-on-primary-container)" />
        <b className='title-large'>{fullName}</b>
        <p className='title-medium'>{institucion}</p>
      </div>

    </div>
  );
}
