import './Convenios.css';
import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { getFirestore, query, collection, orderBy, getDocs } from "firebase/firestore";

export default function Convenios() {
    const [conveniosList, setConveniosList] = useState([]);

    const db = getFirestore();

    useEffect(() => {
        const fetchData = async () => {
            const q = query(collection(db, "agreements"), orderBy("brand", "asc"));
            const querySnapshot = await getDocs(q);
            var array = [];
            querySnapshot.forEach((doc) => {
                array.push({
                    id: doc.id,
                    title: doc.data().title,
                    description: doc.data().description,
                    shortDescription: doc.data().shortDescription,
                    promo: doc.data().promo,
                    logo: doc.data().logo,
                    phone: doc.data().phone,
                    brand: doc.data().brand,
                });
            });
            setConveniosList(array);
        }
        fetchData();
    }, []);

    const list = conveniosList.map(data =>
        <ConvenioCard
            key={data.id}
            id={data.id}
            title={data.title}
            description={data.description}
            shortDescription={data.shortDescription}
            promo={data.promo}
            brand={data.brand}
            logo={data.logo}
            phone={data.phone}
        />
    )

    return (
        <div className='page'>
            <h1 className='display-small'>Convenios</h1>
            {list}
        </div>
    );
}

function ConvenioCard({ id, logo, brand, promo, shortDescription }) {
    return (
        <div>
            <div className="convenio-card">
                <img src={logo} className="convenio-logo" />
                <h6>{brand.toUpperCase()}</h6>
                <h2>{promo}</h2>
                <p>{shortDescription}</p>
                <Link to={"/convenios/" + id}>
                    <button className="text-button">Mas informacion</button>
                </Link>
            </div>
        </div>
    );
}