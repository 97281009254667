import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { getAuth, sendSignInLinkToEmail } from "firebase/auth";
import { MailLinkRegular } from "@fluentui/react-icons";

import './Signup.css';

export default function Signup({ authState, userVerified }) {
    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    let navigate = useNavigate();

    const emailSignIn = e => {
        e.preventDefault();
        const actionCodeSettings = {
            // url: 'http://localhost:3000',
            url: 'https://anep.app',
            handleCodeInApp: true,
            iOS: {
                bundleId: 'app.anep'
            },
            android: {
                packageName: 'app.anep.twa',
                installApp: true
                // minimumVersion: '2'
            },
            dynamicLinkDomain: 'anep.page.link'
        };

        const auth = getAuth();
        sendSignInLinkToEmail(auth, email, actionCodeSettings)
            .then(() => {
                window.localStorage.setItem('emailForSignIn', email);
                navigate({ pathname: 'emailsent', search: 'email=' + email });
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                switch (errorCode) {
                    case 'auth/missing-email':
                        setErrorMessage('Debes ingresar un correo');
                        break;

                    default:
                        setErrorMessage(errorMessage);
                        break;
                }


                console.log(errorCode, errorMessage);
            });
    }

    if (authState === false) {
        return (
            <div className='page'>

                <h1>Crear cuenta</h1>

                <div className='signup-card'>
                    <MailLinkRegular fontSize={36} />

                    <h3>Ingresa con tu correo para empezar</h3>

                    <p>Para comenzar con el proceso de afiliación o vinculación de cuenta en caso que ya estes afiliado/a a ANEP, te enviaremos un link de confirmación a tu bandeja de entrada</p>

                    <form className='signup-form' onSubmit={emailSignIn}>
                        <div className="input-container signup-email-input">
                            <input className="input-normal" id="email" type="email" value={email} onChange={(e) => { setEmail(e.target.value) }} />
                            <label className="input-label" htmlFor="email">Tu email</label>
                            <p style={{ color: 'var(--color-error)' }}><small>{errorMessage}</small></p>
                        </div>
                        <button className='filled-button' type='submit' style={{ float: 'right' }}>Crear cuenta</button>
                    </form>
                </div>

            </div>
        );
    } else if (authState && userVerified) {
        return (
            <h1>Cuenta y afiliacion creada con exito</h1>
        );
    }
    // return(
    //     <div className='page'>

    //         {authState && <Navigate to="afiliate" replace={true} /> }

    //         <h1>Crear cuenta</h1>

    //         <div className='signup-card'>
    //             <MailLinkRegular fontSize={36} />

    //             <h3>Ingresa con tu correo para empezar</h3>

    //             <p>Para comenzar con el proceso de afiliación o vinculación de cuenta en caso que ya estes afiliado/a a ANEP, te enviaremos un link de confirmación a tu bandeja de entrada</p>

    //             <form className='signup-form' onSubmit={emailSignIn}>
    //                 <div className="input-container signup-email-input">
    //                     <input className="input-normal" id="email" type="email" value={email} onChange={(e) => {setEmail(e.target.value)}}/>
    //                     <label className="input-label" htmlFor="email">Tu email</label>
    //                     <p style={{color: 'var(--color-error)'}}><small>{errorMessage}</small></p>
    //                 </div>
    //                 <button className='filled-button' type='submit' style={{float: 'right'}}>Crear cuenta</button>
    //             </form>
    //         </div>

    //     </div>
    // );
}