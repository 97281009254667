import './ModalNavigationDrawer.css';
import {
    HomeRegular,
    HomeFilled,
    NewsRegular,
    NewsFilled,
    TagRegular,
    TagFilled,
    PersonRegular,
    PersonFilled,
    CallRegular,
    CallFilled,
    BriefcaseRegular,
    BriefcaseFilled,
    ShieldTaskRegular,
    ShieldTaskFilled
} from "@fluentui/react-icons";
import {
    Link,
    useMatch,
    useResolvedPath
} from "react-router-dom";

export default function ModalNavigationDrawer() {

    const close = e => {
        if (e.target.id === "scrim") {
            document.getElementById('modal-navigation-drawer').classList.remove("modal-navigation-drawer-opened");
            document.getElementById('scrim').classList.remove("scrim-visible");
        }
    }

    const closeOnLink = e => {
        document.getElementById('modal-navigation-drawer').classList.remove("modal-navigation-drawer-opened");
        document.getElementById('scrim').classList.remove("scrim-visible");
    }


    return (

        <div className='scrim' id="scrim" onClick={close}>
            <nav className='modal-navigation-drawer' id="modal-navigation-drawer">

                <CustomLink
                    to="/"
                    state={{ title: 'ANEP' }}
                    icon={
                        <span className='material-symbols'>
                            home
                        </span>
                    }
                    activeicon={
                        <span className='material-symbols active'>
                            home
                        </span>
                    }
                    onClick={closeOnLink}>
                    Home
                </CustomLink>


                <CustomLink
                    to="calendario"
                    state={{ title: 'Calendario' }}
                    icon={
                        <span className='material-symbols'>
                            event
                        </span>
                    }
                    activeicon={
                        <span className='material-symbols active'>
                            event
                        </span>
                    }
                    onClick={closeOnLink}>
                    Calendario
                </CustomLink>

                <CustomLink
                    to="noticias"
                    state={{ title: 'Noticias' }}
                    icon={
                        <span className='material-symbols'>
                            newsmode
                        </span>
                    }
                    activeicon={
                        <span className='material-symbols active'>
                            newsmode
                        </span>
                    }
                    onClick={closeOnLink}>
                    Noticias
                </CustomLink>

                <CustomLink
                    to="convenios"
                    state={{ title: 'Convenios' }}
                    icon={
                        <span className='material-symbols'>
                            loyalty
                        </span>
                    }
                    activeicon={
                        <span className='material-symbols active'>
                            loyalty
                        </span>
                    }
                    onClick={closeOnLink}>
                    Convenios
                </CustomLink>

                {/* <CustomLink
                    to="carne"
                    state={{ title: 'Carnet' }}
                    icon={
                        <span className='material-symbols'>
                            badge
                        </span>
                    }
                    activeicon={
                        <span className='material-symbols active'>
                            badge
                        </span>
                    }
                    onClick={closeOnLink}>
                    Carnet
                </CustomLink> */}


                <hr style={{ margin: '1rem  0', borderColor: 'var(--outline)' }}></hr>

                {/* <CustomLink 
                to="calendario" 
                state={{title: 'enotar.io'}}
                icon={<HomeRegular fontSize={24} />} 
                activeicon={<HomeFilled fontSize={24} />}
                onClick={closeOnLink}>
                Calendario
            </CustomLink> */}

                {/* <CustomLink
                    to="poliza"
                    state={{ title: 'Polizas' }}
                    icon={<ShieldTaskRegular fontSize={24} />}
                    activeicon={<ShieldTaskFilled fontSize={24} />}
                    onClick={closeOnLink}>
                    Polizas
                </CustomLink> */}

                <CustomLink
                    to="poliza"
                    state={{ title: 'Convenios' }}
                    icon={
                        <span className='material-symbols'>
                            shield_person
                        </span>
                    }
                    activeicon={
                        <span className='material-symbols active'>
                            shield_person
                        </span>
                    }
                    onClick={closeOnLink}>
                    Polizas
                </CustomLink>

                <CustomLink
                    to="legal"
                    state={{ title: 'Departamento legal' }}
                    icon={
                        <span className='material-symbols'>
                            cases
                        </span>
                    }
                    activeicon={
                        <span className='material-symbols active'>
                            cases
                        </span>
                    }
                    onClick={closeOnLink}>
                    Casos legales
                </CustomLink>


                <hr style={{ margin: '1rem  0', borderColor: 'var(--outline)' }}></hr>

                <CustomLink
                    to="recreacion"
                    state={{ title: 'Centro recreativo' }}
                    icon={
                        <span className='material-symbols'>
                            beach_access
                        </span>
                    }
                    activeicon={
                        <span className='material-symbols active'>
                            beach_access
                        </span>
                    }
                    onClick={closeOnLink}>
                    Centro Recreativo
                </CustomLink>

                {/* <CustomLink
                to="directorio" 
                state={{title: 'Directorio'}}
                icon={<CallRegular fontSize={24} />} 
                activeicon={<CallFilled fontSize={24} />}
                onClick={closeOnLink}>
                Directorio
            </CustomLink> */}

                {/* <CustomLink
                to="legal" 
                state={{title: 'Legal'}}
                icon={<BriefcaseRegular fontSize={24} />} 
                activeicon={<BriefcaseFilled fontSize={24} />}
                onClick={closeOnLink}>
                Legal
            </CustomLink> */}

                {/* <CustomLink
                to="afiliate" 
                state={{title: 'Afiliate'}}
                icon={<InfoRegular fontSize={24} />} 
                activeicon={<InfoFilled fontSize={24} />}
                onClick={closeOnLink}>
                Afiliate
            </CustomLink> */}

            </nav>
        </div>
    )
};

function CustomLink({ children, to, ...props }) {
    let resolved = useResolvedPath(to);
    let match = useMatch({ path: resolved.pathname, end: true });

    return (
        <Link to={to} {...props} className={match ? "navigation-drawer-item navigation-drawer-item-match" : "navigation-drawer-item"}>
            {match ? props.activeicon : props.icon}
            {children}
        </Link>
    );
}