import './TopAppBar.css';
import {
    NavigationRegular,
    ArrowLeftRegular
} from "@fluentui/react-icons";
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

export default function TopAppBar() {
    const [title, setTitle] = useState('');

    const location = useLocation();

    useEffect(() => {

        if (location.state) {
            setTitle(location.state.title);
        } else {
            setTitle('ANEP');
        }

    }, [location]);

    var scrollTrigger = 64;

    window.onscroll = function () {
        if (window.scrollY >= scrollTrigger) {
            document.getElementById("top-app-bar-surface-tint").classList.add("top-app-bar-surface-tint-scroll");
            document.getElementById("top-app-bar-title").classList.add("top-app-bar-title-scroll");

        } else {
            document.getElementById("top-app-bar-surface-tint").classList.remove("top-app-bar-surface-tint-scroll");
            document.getElementById("top-app-bar-title").classList.remove("top-app-bar-title-scroll");
        }
    };

    const abrir = () => {
        document.getElementById('modal-navigation-drawer').classList.add("modal-navigation-drawer-opened");
        document.getElementById('scrim').classList.add("scrim-visible");
    }

    return (
        <header className='top-app-bar'>
            <div className='top-app-bar-surface-tint' id='top-app-bar-surface-tint'>
                {
                    location.pathname === '/' ?
                        <button className='icon-button' onClick={abrir}>
                            {/* <NavigationRegular fontSize={24} /> */}
                            <span className="material-symbols">
                                menu
                            </span>
                        </button> :
                        <button className='icon-button' onClick={() => { window.history.back() }}>
                            {/* <ArrowLeftRegular fontSize={24} /> */}
                            <span className="material-symbols">
                                arrow_back
                            </span>
                        </button>
                }

                <h3 className='top-app-bar-title title-large' id='top-app-bar-title'>{title}</h3>

                {/* <h1>{title}</h1> */}
            </div>
        </header>
    )
};