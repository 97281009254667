import styles from './Calendario.module.css';
import React, { useEffect, useState } from 'react';
import { getFirestore, query, collection, orderBy, limit, getDocs, where } from "firebase/firestore";
// BANNERS MESES
import enero from '../assets/meses/enero.jpg';
import febrero from '../assets/meses/febrero.jpg';
import marzo from '../assets/meses/marzo.jpg';
import abril from '../assets/meses/abril.jpg';
import mayo from '../assets/meses/mayo.jpg';
import junio from '../assets/meses/junio.jpg';
import julio from '../assets/meses/julio.jpg';
import agosto from '../assets/meses/agosto.jpg';
import septiembre from '../assets/meses/setiembre.jpg';
import octubre from '../assets/meses/octubre.jpg';
import noviembre from '../assets/meses/noviembre.jpg';
import diciembre from '../assets/meses/diciembre.jpg';

const images = {
    Enero: enero,
    Febrero: febrero,
    Marzo: marzo,
    Abril: abril,
    Mayo: mayo,
    Junio: junio,
    Julio: julio,
    Agosto: agosto,
    Septiembre: septiembre,
    Octubre: octubre,
    Noviembre: noviembre,
    Diciembre: diciembre
};

export default function Calendario() {

    const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    const date = new Date();
    const currentMonth = months[date.getMonth()];

    const [selectedMonth, setSelectedMonth] = useState(currentMonth);
    const [eventos, setEventos] = useState([]);

    const [calendarioList, setCalendarioList] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const db = getFirestore();
            const dateRange = getMonthDateRange(selectedMonth);
            if (!dateRange) return;

            try {

                const q = query(collection(db, "calendario"), where("inicio", ">=", dateRange.start), where("inicio", "<=", dateRange.end));
                const querySnapshot = await getDocs(q);

                const newEventos = querySnapshot.docs.map(doc => {
                    const data = doc.data();
                    const titulo = data.titulo || "Titulo no definido";
                    const descripcion = data.descripcion || "Este evento no tiene descripcion";
                    const categoria = data.categoria || "Sin Categoria";
                    const inicio = data.inicio?.toDate() || new Date();
                    return { id: doc.id, titulo, descripcion, categoria, inicio };
                });

                console.log(newEventos);
                setEventos(newEventos);
            } catch (error) {
                console.error("Error getting documents: ", error);
            }
        };

        fetchData();
    }, [selectedMonth]);

    const getMonthDateRange = (month) => {
        const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
        const monthIndex = months.indexOf(month);

        if (monthIndex === -1) return null;

        const currentYear = new Date().getFullYear();
        const start = new Date(currentYear, monthIndex, 1);
        const end = new Date(currentYear, monthIndex + 1, 0, 23, 59, 59);

        return { start, end };
    };

    return (
        <div>

            <div className={styles.header}>
                <h1 className='display-small' >Calendario</h1>
                <select className='input-normal' value={selectedMonth} onChange={e => setSelectedMonth(e.target.value)} style={{ width: 'fit-content' }}>
                    {months.map((month, index) => (
                        <option key={index} value={month}>
                            {month}
                        </option>
                    ))}
                </select>
            </div>



            <img className={styles.banner} src={images[selectedMonth]} alt="Calendario" />
            <section className={styles.content}>
                <p style={{ marginBottom: '1rem', opacity: '0.7' }}>En el mes de {selectedMonth}:</p>

                {
                    eventos.map(evento => (
                        <Event key={evento.id} titulo={evento.titulo} categoria={evento.categoria} inicio={evento.inicio} />
                    ))
                }

            </section>

            {/* {list} */}
        </div>
    );
}

function Event({ id, titulo, descripcion, categoria, inicio }) {
    function formatDate(date) {
        const options = { month: 'short', day: '2-digit' };
        return date.toLocaleDateString('es-ES', options).toUpperCase();
    }

    function getClassName(categoria) {
        switch (categoria) {
            case 'feriado':
                return styles.feriado;
            case 'pago':
                return styles.pago;
            case 'asamblea':
                return styles.asamblea;
            case 'celebracion':
                return styles.celebracion;
            default:
                return '';
        }
    }

    return (
        <div className={`${styles.event} ${getClassName(categoria)}`}>
            <div>
                <p className='title-medium'>{titulo}</p>
                <span className='label-medium'>{categoria.toUpperCase()}</span>
            </div>
            <span className='label-medium'>{formatDate(inicio)}</span>
        </div>
    );
}